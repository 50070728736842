.bar-graph {
  width: 80vw;
}

.bar-tooltip {
  background-color: white;
  border: 1px solid #ccc; 
  padding: 10px;
  color: black 
}

